import {
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { throwError } from 'rxjs';
import { catchError, exhaustMap, take, tap } from 'rxjs/operators';

import { environment } from 'projects/ehr/src/environments/environment';
import { UserDataService } from './user-data.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterCeptor implements HttpInterceptor {
  constructor(
    private userDataService: UserDataService,
    private router: Router
  ) {}
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (req.url.includes(environment.apiUrl)) {
      return this.userDataService.isUser.pipe(
        take(1),
        exhaustMap((expToken) => {
          if (!expToken) {
            return next.handle(req);
          }
          const modifiedReq = req.clone({
            headers: new HttpHeaders().set('Authorization', expToken.token),
          });
          return next.handle(modifiedReq).pipe(
            catchError((err) => {
              console.log(err);
              if (err.error.error == 'Invalid token or session expired') {
                this.userDataService.logOut();
                return;
              }
              return throwError(err);
            }),
            tap((data: any) => {
              if (data.status === 200) {
                this.userDataService.refreshToken(expToken.token);
              }
            })
          );
        })
      );
    }

    return next.handle(req);
  }
}
