import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, filter, map, tap } from 'rxjs/operators';

import { EditUserProfile, TokenExp, User } from '../../shared/_models/User.modal';
import { UploadFile } from './uploadFile.service';

@Injectable({
  providedIn: 'root',
})
export class UserDataService {
  public user = new BehaviorSubject<User>(null);
  public isUser = new BehaviorSubject<TokenExp>(null);
  private api = 'https://api.romtix.com/api/';
  private timer: any = null;
  private hour = 3600000;
  private userInfo = new BehaviorSubject<any>(null);
  public isReadOnlyUser: boolean = false;
  constructor(
    private http: HttpClient,
    private router: Router,
    private modalService: NgbModal,
    private uploadService: UploadFile
  ) {}

  get userInfo$(): Observable<any> {
    return this.userInfo.asObservable();
  }

  login(userName: string, password: string , response: string) {
    var dt = new Date();
    return this.http
      .post<any>(`${this.api}login`, {
        accessCode: userName,
        verifyCode: password,
        googleToken: response,
        timeDiff:
          (-dt.getTimezoneOffset() < 0 ? '-' : '+') +
          Math.abs(dt.getTimezoneOffset() / 60),
      })
      .pipe(
        catchError((err) => throwError(err)),
        tap((resData) => {
          if(resData.token){
            localStorage.clear();
            this.handleAuthentication(resData.token);
          }
        })
      );
  }

  twoFactorAuthentication(code:number): Observable<any> {
    var dt = new Date();
    return this.http
      .post<any>(`${this.api}verify2FA`, {
        code: code,
        timeDiff:
          (-dt.getTimezoneOffset() < 0 ? '-' : '+') +
          Math.abs(dt.getTimezoneOffset() / 60),
      })
      .pipe(
        catchError((err) => throwError(err)),
        tap((resData) => {
          if(resData.token){
            localStorage.clear();
            this.handleAuthentication(resData.token);
          }
        })
      );
  }

  logOut() {
    this.http.post(`${this.api}logout`, {}).subscribe(
      (resp) => {
        this.forceLogOut();
      },
      (err) => this.forceLogOut()
    );
  }

  forceLogOut() {
    localStorage.clear();
    this.isUser.next(null);
    this.user.next(null);
    this.modalService.dismissAll('LogOut');
    // this.router.navigate(['/auth'], { replaceUrl: true });
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = null;
    document.location.reload();
  }

  changePassword(pass: string, newPass: string, confirmPass: string) {
    return this.http.post(`${this.api}cvc`, {
      currentVerifyCode: pass,
      newVerifyCode: newPass,
      confirmNewVerifyCode: confirmPass,
    });
  }

  setAvailability(available: 0 | 1) {
    return this.http.get(`${this.api}setAvailability?available=${available}`);
  }

  changeSignature(esc: string) {
    return this.http.get(`${this.api}changeElectronicSignature?esc=${esc}`);
  }

  autoLogin() {
    const expInfo: {
      _token: string;
      _tokenExpDate: string;
    } = JSON.parse(localStorage.getItem('tokenInfo'));
    if (!expInfo) {
      return;
    }
    const newExpToken = new TokenExp(
      expInfo._token,
      new Date(expInfo._tokenExpDate)
    );

    const loadedUser: User = JSON.parse(localStorage.getItem('userData'));

    if (newExpToken.token) {
      this.isUser.next(newExpToken);
      this.user.next(loadedUser);
      const expirationDuration =
        new Date(expInfo._tokenExpDate).getTime() - new Date().getTime();
      this.autoLogout(expirationDuration);
      this.setUserInfo();
    } else {
      localStorage.clear();
    }
  }

  autoLogout(duration) {
    this.timer = setTimeout(() => {
      this.logOut();
    }, duration);
  }

  handleAuthentication(token: string) {
    this.refreshToken(token);
    this.setUserInfo();
  }

  setUserInfo() {
    this.getUserInfo().subscribe((user: User) => {
      this.setGoogleClientId(user, true);
    });
  }

  async setGoogleClientId(user: User, goHome) {
    if (user.pages?.includes('calender') || user.pages?.includes('patients')) {
      await this.uploadService
        .getSettingsValue('GOOGLE APP ID')
        .toPromise()
        .then((clientId: any) => {
          localStorage.setItem('client_Id', clientId.value);
        });
    }
    localStorage.setItem('userData', JSON.stringify(user));
    this.user.next(user);
    let curUrl = this.router.url;
    if (curUrl.includes('home')) {
      this.router.navigate([curUrl]);
    } else {
      this.router.navigate(['/home/']);
    }
  }

  getUserInfo() {
    return this.http
      .get(`${this.api}getUserInfo`)
      .pipe(
        map((data: any) => data.userInfo),
        tap((userInfo) => {
          this.userInfo.next(userInfo);  
          this.isReadOnlyUser = userInfo?.readOnly === 1;
        })
      );
  }

  ReadOnlyUser(): Observable<boolean> {
    return this.userInfo$.pipe(
      filter((userInfo) => userInfo !== null),
      map((userInfo) => userInfo.readOnly === 1)
    );
  }

  isReadOnly(): boolean {
    return this.isReadOnlyUser;
  }

  getGoogleClientId() {
    return this.http
      .get(`${this.api}getGoogleClientId`)
      .pipe(map((data: any) => data.clientId));
  }

  updateGoogleClientId(clientId: string) {
    return this.http.get(
      `${this.api}updateGoogleClientId?clientId=${clientId}`
    );
  }

  getProviders(id: number = null) {
    if (id) {
      return this.http
        .get(`${this.api}getProviders?patientId=${id}`)
        .pipe(map((data: any) => data.providers));
    } else {
      return this.http
        .get(`${this.api}getProviders`)
        .pipe(map((data: any) => data.providers));
    }
  }

  getClinics(userId: number) {
    return this.http
      .get(`${this.api}getClinics?userId=${userId}`)
      .pipe(map((data: any) => data.clinics));
  }

  getUserSpecialties() {
    return this.http
      .get(`${this.api}getUserSpecialties`)
      .pipe(map((data: any) => data.specialties));
  }

  setUserSpecialty(specialtyId: number, status: 0 | 1) {
    return this.http.get(
      `${this.api}setUserSpecialty?specialtyId=${specialtyId}&status=${status}`
    );
  }

  getUserPopulationGroups() {
    return this.http
      .get(`${this.api}getUserPopulationGroups`)
      .pipe(map((data: any) => data.populationGroups));
  }

  setUserPopulationGroup(groupId: number, status: 0 | 1) {
    return this.http.get(
      `${this.api}setUserPopulationGroup?groupId=${groupId}&status=${status}`
    );
  }
  editUserProfile(body: EditUserProfile){
  return this.http.post(`${this.api}editProfile`, body)
  }
  serverInfo() {
    return this.http.get(this.api + 'info').pipe(tap((resData) => {}));
  }
  generateAuthSecret(){
    return this.http.get(`${this.api}generateAuthSecret`)
  }
  verifyAuthSecret(code: string, userId: number, includeAuthLogic: boolean = true): Observable<any> {
    const dt = new Date();
    const baseObservable = this.http.post<any>(`${this.api}verifyAuthSecret`, {
      token: code,
      userId: userId,
      timeDiff:
        (-dt.getTimezoneOffset() < 0 ? '-' : '+') 
        + Math.abs(dt.getTimezoneOffset() / 60),
    });
    if (includeAuthLogic) {
      return baseObservable.pipe(
        catchError((err) => throwError(err)),
        tap((resData) => {
          if (resData.token) {
            localStorage.clear();
            this.handleAuthentication(resData.token);
          }
        })
      );
    }
    return baseObservable;
  }  
  refreshToken(token: string) {
    const expToken = new TokenExp(
      token,
      new Date(new Date().getTime() + this.hour)
    );
    this.isUser.next(expToken);
    localStorage.setItem('tokenInfo', JSON.stringify(expToken));
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = null;
    this.autoLogout(this.hour);
  }
}
