import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpProgressEvent,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';

export interface Upload {
  progress: number;
  state: 'PENDING' | 'IN_PROGRESS' | 'DONE';
}

@Injectable({
  providedIn: 'root',
})
export class UploadFile {
  private api = 'https://api.romtix.com/api/';

  constructor(private http: HttpClient) {}

  upload(file: File) {
    const data = new FormData();
    data.append('singlefile', file);
    return this.http.post(`${this.api}upload`, data, {
      reportProgress: true,
      observe: 'events',
    });
  }

  getSettingsValue(setting: string) {
    return this.http.get(`${this.api}getSettingsValue?setting=${setting}`);
  }

  setSettingsValue(setting: string, value: any) {
    return this.http.get(
      `${this.api}setSettingsValue?setting=${setting}&value=${value}`
    );
  }
  signOnSetUp() {
    return this.http.post(`${this.api}signonSetup`, '');
  }
  sendEmail(body: any) {
    return this.http.post(`${this.api}sendEmail`, body);
  }
}
