import { Injectable } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ScreenSizeService {
    isSmallScreen$: Observable<boolean>;

    private readonly CUSTOM_BREAKPOINT = '(max-width: 700px)';
  
    constructor(private breakpointObserver: BreakpointObserver) {
      this.isSmallScreen$ = this.breakpointObserver.observe([this.CUSTOM_BREAKPOINT])
        .pipe(
          map((result: BreakpointState) => result.matches)
        );
    }
}
